import Layout from '@components/Layout';
import { Page404 } from '@components/Page404';

type HomeComponent = { Layout: typeof Layout };

const Search: HomeComponent = () => <Page404 />;

Search.Layout = Layout;

export default Search;
